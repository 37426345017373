import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HomeTop = _resolveComponent("HomeTop")!
  const _component_HomeServices = _resolveComponent("HomeServices")!
  const _component_HomeWhoWeAre = _resolveComponent("HomeWhoWeAre")!
  const _component_HomeWhyExive = _resolveComponent("HomeWhyExive")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_HomeTop),
    _createVNode(_component_HomeServices, { class: "home-services animate__animated animate__fadeInUp" }),
    _createVNode(_component_HomeWhoWeAre, { class: "home-who-we-are" }),
    _createVNode(_component_HomeWhyExive, { class: "home-why-exive" })
  ], 64))
}