
type SeparatorProps = {
  weight: string;
  opacity: number;
};

type StyleType = {
  "border-top": string;
  opacity: number;
};

type SeparatorResult = {
  style: StyleType;
};

export default {
  name: "Separator",
  props: {
    weight: {
      type: String,
      required: true,
    },
    opacity: {
      type: Number,
      required: true,
    },
  },

  setup(props: SeparatorProps): SeparatorResult {
    const style: StyleType = {
      "border-top": props.weight,
      opacity: props.opacity,
    };

    return {
      style,
    };
  },
};
