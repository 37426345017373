import { createI18n } from "vue-i18n";
import pl from "./pl";

const i18n = createI18n({
  locale: "pl",
  fallbackLocale: "pl",
  messages: {
    pl,
  },
});

export default i18n;
