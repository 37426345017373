const home = {
  services: {
    properties: "NIERUCHOMOŚCI",
    "properties-description-1": "Nieruchomości własne",
    "properties-description-2": "Oferty od Partnerów",
    finances: "FINANSOWANIE",
    "finances-description-1": "Kredyty hipoteczne",
    "finances-description-2": "Kredyty gotówkowe/konsolidacyjne",
    "finances-description-3": "Kredyty firmowe",
    "finances-description-4": "Leasingi",
    insurances: "UBEZPIECZENIA",
    "insurances-description-1": "Kompleksowe ubezpieczenia",
    "insurances-description-2": "dla Ciebie i Twojej firmy",
  },
  "who-we-are": {
    title: "KIM JESTEŚMY?",
    subtitle: "Opracowujemy rozwiązania dla naszych klientów",
    "description-1":
      " Zapewniamy kompleksowe ubezpieczenia we współpracy ze\n" +
      "        wszystkimi towarzystwami ubezpieczeniowymi dostępnymi na\n" +
      "        rynku. Przygotowujemy oferty dla klientów indywidualnych i firmowych.\n" +
      "        niezbędnego finansowania.",
    "description-2":
      "Kiedy zapytasz nas, jaki aspekt jest najważniejszy w naszej pracy, zawsze\n" +
      "        odpowiemy: zadowolenie naszych klientów.\n",
    "description-3":
      " Kiedy zapytasz nas jak to zrobić – powiemy: z Exive. Posiadamy\n" +
      "        kilkunastoletnie doświadczenie w branży finansowej. A to sprawia, że wiemy,\n" +
      "        jak rozmawiać, by stworzyć konkretną ofertę dopasowaną do Twoich potrzeb,\n" +
      "        a wszystko to w oparciu o przejrzyste zasady.",
    "description-4": " Kompleksowo wspomożemy Twoje finanse.\n",
    "description-5": " Co możemy dla Ciebie zrobić?\n",
  },
  "why-exive": {
    "question-title": "DLACZEGO EXIVE ?",
    "question-subtitle": "CZYLI CO ZYSKUJESZ WSPÓŁPRACUJĄC Z NAMI?",
    "answer-title-time-description":
      "Czas to wartość, której nie można kupić. Tak mówią wszyscy i w wielu kwestiach zgadzamy\n" +
      "          się z tym twierdzeniem. Jednak nie w przypadku naszego biznesu. U nas kupujesz czas, w\n" +
      "          którym możesz pracować, odpoczywać, realizować swoje pasje. Kupujesz czas bez zmartwień.\n" +
      "          To my pomagamy uzyskać Ci najbardziej odpowiednią ofertę, a w razie jakichkolwiek\n" +
      "          komplikacji, czy konieczności uzyskania odszkodowania jesteśmy do Twojej dyspozycji.\n" +
      "          A wtedy Ty zarabiasz pieniądze, cieszysz się spokojem i przeznaczasz zaoszczędzony czas\n" +
      "          na życie, takie jak lubisz.",
    "answer-title-time": "CZAS",
    "answer-title-security": "BEZPIECZEŃSTWO",
    "answer-title-security-description":
      "  Zasady naszego działania są proste i przejrzyste. System działania, który wypracowaliśmy sprawia, że\n" +
      "          możesz spać spokojnie. Pozostajemy w stałym kontakcie ze swoimi klientami. Informujemy o\n" +
      "          zmianach na rynku, które mogą ich dotyczyć i czuwamy nad tym, by cały proces współpracy z nami\n" +
      "          przebiegał z korzyścią dla klienta, czyli dla Ciebie.\n",
    "answer-title-money": "PIENIĄDZE",
    "answer-title-money-description":
      "  Podobno o nich się nie mówi, ale skoro przeglądasz ten folder i dotarłeś aż tutaj, to\n" +
      "          właśnie dlatego, że obok bezcennego czasu cenisz sobie również swoje pieniądze.\n" +
      "          Wybierając nas jako partnera w finansach, masz pewność że będą one w dobrych rękach.\n" +
      "          Przede wszystkim interesuje nas, aby nasze rozwiązania spełniały Twoje wymagania.\n" +
      "          Jednak zdajemy sobie też  sprawę, że cena musi być rozsądna. Dlatego to my negocjujemy\n" +
      "          dla Ciebie stawki rozwiązań kredytowych i ubezpieczeń.\n",
  },
  top: {
    title1: "NOWY WYMIAR",
    title2: "TWOICH FINANSÓW",
  },
};

export default home;
