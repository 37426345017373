import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-01a24268"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "footer-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FooterContact = _resolveComponent("FooterContact")!
  const _component_Separator = _resolveComponent("Separator")!
  const _component_FooterMenu = _resolveComponent("FooterMenu")!
  const _component_FooterRights = _resolveComponent("FooterRights")!

  return (_openBlock(), _createElementBlock("footer", _hoisted_1, [
    _createVNode(_component_FooterContact, { class: "footer-contact" }),
    _createVNode(_component_Separator, {
      class: "footer-separator",
      weight: "1px",
      opacity: "0.4"
    }),
    _createVNode(_component_FooterMenu, { class: "footer-menu" }),
    _createVNode(_component_FooterRights, { class: "footer-rights" })
  ]))
}