<template>
  <Header id="header-section" />
  <section id="content-section">
    <router-view />
  </section>
  <Footer id="footer-section" />
</template>

<style lang="stylus">
@import "assets/styles/global-variables.styl"
body
  background-color $white-color
  margin 0
  width auto
#content-section
  margin-bottom 6.5%

@media only screen and (max-width: 992px)
  #content-section
    margin-bottom 10%
@media only screen and (max-width: 768px)
  #content-section
    margin-bottom 12.5%
@media only screen and (max-width: 576px)
  #content-section
    margin-bottom 15%
</style>
<script>
import Footer from "@/components/footer/Footer.vue";
import Header from "@/components/header/Header.vue";

export default {
  components: { Header, Footer },
};
</script>
