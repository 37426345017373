
import { defineComponent } from "vue";
import HomeTop from "@/views/home/components/HomeTop.vue";
import HomeServices from "@/views/home/components/HomeServices.vue";
import HomeWhoWeAre from "@/views/home/components/HomeWhoWeAre.vue";
import HomeWhyExive from "@/views/home/components/HomeWhyExive.vue";

export default defineComponent({
  name: "Home",
  components: {
    HomeTop,
    HomeServices,
    HomeWhoWeAre,
    HomeWhyExive,
  },
});
