
import FooterContact from "@/components/footer/components/FooterContact.vue";
import Separator from "@/components/separator/Separator.vue";
import FooterMenu from "@/components/footer/components/FooterMenu.vue";
import FooterRights from "@/components/footer/components/FooterRights.vue";

export default {
  name: "Footer",
  components: { Separator, FooterContact, FooterMenu, FooterRights },
};
