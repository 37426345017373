const footer = {
  menu: {
    properties: "NIERUCHOMOŚCI",
    finances: "FINANSOWANIE",
    insurances: "UBEZPIECZENIA",
  },
  contact: {
    "contact-us": "SKONTAKTUJ SIĘ Z NAMI,",
    "comfortable-way": "W WYGODNY DLA CIEBIE SPOSÓB.",
    phone1: "+48 511 996 583",
    phone2: "+48 501 498 702",
    "address-line-1": "Wszędzie tam, gdzie",
    "address-line-2": "nas potrzebujesz",
  },
};

export default footer;
